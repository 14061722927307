import {PostRequest} from '../Faq/Models/Requests'
import ApiResponse from '../Faq/Models/ApiResponse'

const CreateTicket = async (email, name, subject, description, product) => {
    let apiResponse;
    if (process.env.NODE_ENV !== "development") {
      apiResponse = await PostRequest("https://restlessmonks.freshdesk.com/api/v2/tickets", {
        email, 
        name, 
        subject,
        description,
        priority: 2,
        status: 2,
        custom_fields: {
          cf_select_product: product
        }
      }, {
        Authorization: btoa('NLybTAXgSHObJLCLIT:X')
      })
    } else {
      apiResponse = new ApiResponse(dummyPlans(), 200, null)
    }
    if (apiResponse.body) {
      return apiResponse.body;
    } else {
      return apiResponse.error
    }
}

function dummyPlans() {
    return {}
  }
  

export default CreateTicket