import React, { ReactNode } from 'react'

type Props = {
  children: ReactNode,
  colorBg?: boolean,
  fullwidth?: boolean,
  centered?: boolean,
  sectionClass?: string
}

const Wrapper = ({
  children,
  sectionClass,
  colorBg = false,
  fullwidth = false,
  centered = false
}: Props) => {
  
  return(
    <div
      className={
        `section 
        ${colorBg ? 'has-background-bg ' : ''}
        ${sectionClass}`
      }
    >
      <div 
        className={`container is-max-widescreen ${centered ? 'has-text-centered': ''}`}
      >
        {children}
      </div>
    </div>
  )
}

export default Wrapper