import React, {useState} from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
/* import image from '../../images/gatsby-icon.png' */
import useSiteMetadata from "../SiteMetadata"
import GetStartedButton from "../commonComponents/GetStartedButton"
import NavbarSocialButton from "../commonComponents/NavbarSocialButton"
import NavbarLeftButton from "../commonComponents/NavbarLeftButton/NavbarLeftButton"
import NavbarRightButton from "../commonComponents/NavbarRightButton/NavbarRightButton"
import './Header.css'

type NavbarItem = {
  title: string,
  to: string,
  internal?: boolean,
  list?: NavbarItem[]
}

const image = '/logos/influbitlogofull.png'

const LeftNavBarItems: NavbarItem[] = [
/*   {
    to: '/#home',
    title: "Home",
    internal: true
  },
  {
    title: 'Docs',
    list: [
      {
        to: '/#overview',
        title: 'Overview',
        internal: true
      },
      {
        to: '/#modifier',
        title: 'Modifier',
        internal: true
      }
    ]
  } */
]

const RightNavbarItems: NavbarItem[] = [
  {
    to: '/#features-section',
    title: 'Features',
    internal: true
  },
  {
    to: '/#pricing-section',
    title: 'Pricing',
    internal: true
  }
]

const Header = () => {
  const {link, title}= useSiteMetadata()
  const [active, setActive] = useState(false)
  const [navBarActiveClass, setNavBarActiveClass] = useState('')

  function toggleHamburger() {
    // toggle the active boolean in the state

    console.log(active)
      setActive(!active)
      // set the class in state for the navbar accordingly
      console.log(active)
      setNavBarActiveClass(active ? 'is-active': '')
  }

  return (
    <nav className="navbar is-fixed-top is-transparent px-4">
      <div className="navbar-brand">
        <Link className="navbar-item" to="/">
          <img
            src={image}
            alt="Framery"
          />
        </Link>
        <div
          className={`navbar-burger ${navBarActiveClass}`}
          /*       className={`header-nav is-vcentered navbar-burger burger ${navBarActiveClass}`} */
          data-target="navMenu"
          onClick={toggleHamburger}
        >
          <span />
          <span />
          <span />
        </div>
      </div>

      <div
        id="navbarExampleTransparentExample"
        className={`navbar-menu ${navBarActiveClass}`}
      >
        <div className="navbar-start">
          {LeftNavBarItems.map((item, i) => (
            <NavbarLeftButton item={item} index={i} />
          ))}
        </div>

        <div className="navbar-end">
          <div className="navbar-item">
            <div className="field is-grouped">
{/*               {RightNavbarItems.map((item, index) => (
                <div 
                  className="control"
                  key={index}
                >
                  <NavbarRightButton 
                    title={item.title}
                    link={item.to}
                    key={index}
                    internal={item.internal}
                  />
                </div>
              ))} */}
              <p className="control">
                <GetStartedButton />
              </p>
            </div>
          </div>
        </div>
      </div>
    </nav>
  )

    return (
      <nav
        className="navbar is-fixed-top"
        role="navigation"
        aria-label="main-navigation"
      >
        <div className="container nav-container">
          <a
            href="/"
            className='logo-link navbar-start'
            title={title}
            onClick={() => {
              trackCustomEvent({
                category: "Logo Button Navbar",
                action: "Click",
                label: "Get Extension Button Navigation Bar"
              })
              return true
            }}
          >
            <img src={'../../images/gatsby-icon.png'} title={title} alt={title} className="logo-img" />
          </a>
          <div
            className={`header-nav is-vcentered navbar-burger burger ${navBarActiveClass}`}
            data-target="navMenu"
            onClick={() => toggleHamburger()}
            style={{
              margin: '1em 1em 0 0'
            }}
          >
            <span />
            <span />
            <span />
          </div>

          <div
            id="navMenu"
            className={`navbar-menu ${navBarActiveClass}`}
          >
            <div className="navbar-end is-vcentered is-size-5-tablet is-uppercase has-text-centered">
{/*               {NavbarItems.map((item, index)=>(
                !item.hide && (
                  <Link key={index} className="navbar-item has-text-weight-bold logo-link is-size-4-desktop is-size-6-tablet is-size-6-mobile" to={item.to} title={item.title}>
                    {item.title}
                  </Link>
                )
              ))} */}
                <a key={5} className="navbar-item has-text-weight-bold logo-link" href={"/install-wizard"} title="Get Growpad For Free" target="_blank">
                <div 
                      className="button has-text-weight-bold is-uppercase is-wrapped is-size-4-desktop is-size-6-tablet is-size-6-mobile" 
                      style={{
                          whiteSpace: 'break-spaces',
                          boxShadow: '0px 6px 12px #00000029',
                          backgroundColor: '#0EB3ED',
                          color: '#fff',
                          borderRadius: '11px'
                        }}
                    >
                      {"Get Started"}
                    </div>
                </a>
            </div>
          </div>

        </div>
      </nav>
    )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

/*   
<header
    style={{
      background: `rebeccapurple`,
      marginBottom: `1.45rem`,
    }}
  >
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        padding: `1.45rem 1.0875rem`,
      }}
    >
      <h1 style={{ margin: 0 }}>
        <Link
          to="/"
          style={{
            color: `white`,
            textDecoration: `none`,
          }}
        >
          {siteTitle}
        </Link>
      </h1>
    </div>
  </header>
 */

export default Header
