import * as React from "react"
import { graphql, PageProps } from "gatsby"
import '../components/all.sass'
import SEO from "../components/Seo"
import useSiteMetadata from "../components/SiteMetadata"
import Layout from "../components/Layout"
import Main from '../components/Main'
import './index.css';

const IndexPage = () => {
  const {title} = useSiteMetadata()

  return (
    <Layout>
      <SEO 
        title="Framery"
        strictTitle
      />
      <Main />
    </Layout>
  )
}

/*
StaticImage
        src="../images/gatsby-astronaut.png"
        width={300}
        quality={95}
        formats={["AUTO", "WEBP", "AVIF"]}
        alt="A Gatsby astronaut"
        style={{ marginBottom: `1.45rem` }}
*/

export default IndexPage
