import React from 'react'
import Wrapper from '../Wrapper'

const titleStyles: React.CSSProperties = {
  letterSpacing: '4.77px'
}

const subTitleStyles: React.CSSProperties = {
  letterSpacing: '3.01px',
  width: '70%'
}

const WaitingSlide = () => {
  return(
    <Wrapper
      colorBg
    >
      <div 
        className="columns"
      >
        <div className="column is-7">
          <div 
            className="title has-text-white pb-5 is-uppercase is-size-2"
            style={titleStyles}
          >
            What are you waiting for? 
          </div>
          <div 
            className="subtitle has-text-white is-size-4"
            style={subTitleStyles}
          >
            If you are finding trouble in managing all the mentions and tracking metrics for your instagram account, we are the solution. We may not be for everybody but we sure work really work for big volume brands!
          </div>
        </div>
        <div className="column is-5">
          <img
            src={'/img/waiting.png'}
            alt="Contact Image"
            style={{
              float: 'right'
            }}
          />
        </div>
      </div>
    </Wrapper>
  )
}

export default WaitingSlide