import React from 'react'
import Wrapper from '../Wrapper'
import ContactForm from './ContactForm'

const Contact = () => {


  return(
    <Wrapper>
      <div className="title has-text-cloud-brown has-text-weight-bold">
        Contact Us
      </div>
      <div
        className="columns is-vcentered"
      >
        <div className="column is-5-desktop is-6-tablet">
          <ContactForm />
        </div>
        <div className="column is-6-desktop is-offset-1-desktop is-0-mobile">
          <img
            src={'/img/contact.png'}
            alt="Contact Image"
            style={{
              float: 'right'
            }}
          />
        </div>
      </div>
    </Wrapper>
  )
}

export default Contact