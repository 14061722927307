import { FacebookOutlined, InstagramOutlined, LinkedinOutlined, TwitterOutlined } from '@ant-design/icons'
import { Link } from 'gatsby'
import React from 'react'

interface SocialAccount {
  name: string,
  link: string,
  icon: any
}

interface Page {
  name: string,
  link: string,
  internal?: boolean
}

interface Compare {
  name: string,
  link: string
}

const pages: Page[] = [
  {
    name: 'Home',
    link: '/',
    internal: true
  },
  {
    name: 'Blog',
    link: '/blog',
    internal: true
  },
  {
    name: 'Help Center',
    link: '/',
    internal: true
  },
  {
    name: 'Pricing',
    link: '/pricing'
  },
  {
    name: 'careers',
    link: '/careers'
  },
  {
    name: 'terms of use',
    link: '/terms-of-use'
  },
  {
    name: 'privacy-policy',
    link: '/privacy-policy'
  }
]

const compares: Compare[] = [
  {
    name: 'xxx',
    link: '/xamw'
  },
  {
    name: 'yy',
    link: '/adwm'
  }
]

const SocialAccounts: SocialAccount[] = [
  {
    name: 'Instagram',
    link: 'https://instagrem.com/amwdmw',
    icon: <InstagramOutlined />
  },
  {
    name: 'Linkedin',
    link: 'https://instagrem.com/amwdmw',
    icon: <LinkedinOutlined />
  },
  {
    name: 'Twitter',
    link: 'https://instagrem.com/amwdmw',
    icon: <TwitterOutlined />
  },
  {
    name: 'Facebook',
    link: 'https://instagrem.com/amwdmw',
    icon: <FacebookOutlined />
  }
]

const Footer = () => {
  return(
    <footer className="footer pb-6">
      <div className="container">
        <div className="content has-text-centered">
{/*           <div className="columns is-centered">
            <div className="column">
              <img 
                src="/img/logo.png"
                alt="Influencerbit"
              />
              <p className="block pt-4">
                Grow your Shopify store with an ecommerce helpdesk that turns customers into fans.
              </p>
              <p className="block">
                {SocialAccounts.map((item, index) => (
                  <span className="px-2">
                    <a
                      href={item.link}
                      target="_blank"
                      key={index}
                      style={{
                        color: '#222525'
                      }}
                    >
                      {item.icon}
                    </a>
                  </span>
                ))}
              </p>
            </div>
          </div>
          <div className="columns has-text-left is-variable is-8 pt-6 pb-6 is-centered">
            <div className="column is-3-desktop is-offset-3-desktop">
              <h6 className="has-text-weight-bold">
                Pages
              </h6>
              <ul>
                {pages.map((item, index) => (
                  <li
                    key={index}
                  >
                    {item.internal ? (
                      <Link 
                        to={item.link}
                        className="has-text-light-gray footer-list-item"
                      >
                        {item.name}
                      </Link>
                    ): (
                      <a
                        href={item.link}
                        className="has-text-light-gray footer-list-item"
                      >
                        {item.name}
                      </a>
                    )}
                  </li>
                ))}
              </ul>
            </div>
            <div className="column is3-desktop">
              <h6 className="has-text-weight-bold">
                Compare
              </h6>
              <ul>
                {compares.map((item, index) => (
                  <li
                    key={index}
                  >
                    <Link 
                      to={item.link}
                      className="has-text-light-gray footer-list-item"
                    >
                      {item.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div> */}
          <p className="pt-4">
            © Framery
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer