import * as React from 'react'
import GetStartedButton from '../commonComponents/GetStartedButton'

interface Props {
  title: string,
  reversed: boolean,
  img?: string,
  desc: string,
  subtitle: string
}

const titleStyle: React.CSSProperties = {
  letterSpacing: '5.85px'
}

const subTitleStyle: React.CSSProperties = {
  letterSpacing: '4.61px'
}

const paraStyle: React.CSSProperties = {
  letterSpacing: '2.69px'
}

const FeatureRow = ({
  title,
  reversed,
  img,
  desc,
  subtitle
}: Props) => {
  return(
    <div className={`columns pt-6 pb-6 is-mobile is-multiline ${reversed && 'is-flex-direction-row-reverse'}`}>
      <div className={`column is-full-mobile is-half-tablet ${reversed ? 'has-text-right' : 'has-text-left'}`}>
        <div 
          className="title is-capitalized has-text-firefly has-text-weight-bold
            is-size-2-desktop
            pb-3"
          style={titleStyle}
        >
          {title}
        </div>
        <div 
          className="has-text-secondary subtitle has-text-weight-bold is-uppercase
          is-size-3-desktop"
          style={subTitleStyle}
        >
          {subtitle}
        </div>
        <p
          className="has-text-weight-regular mb-4"
          style={paraStyle}
        >
          {desc}
        </p>
        <GetStartedButton 
          size="medium"
        />
      </div>
      {/*<div className="column is-half-tablet is-10-mobile">*/}
      {/*  <div className="card feature-card" style={{height: '100%'}}>*/}
      {/*    <div className="card-content feature-image">*/}
      {/*      <img*/}
      {/*        src={img}*/}
      {/*        alt={title}*/}
      {/*      />*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </div>
  )
}

export default FeatureRow