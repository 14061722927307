import * as React from 'react'

const NavbarLeftButton = ({
  item,
  index
}) => {
  return(
    item.list ? (
      <div className="navbar-item has-dropdown is-hoverable">
        <a 
          className="navbar-link" 
          href={item.list[0].to}
        >
          {item.title}
        </a>
        <div className="navbar-dropdown is-boxed">
          {
            item.list.map((child, ci) => (
              <NavbarLeftButton 
                item={child}
                index={ci}
              />
            ))
          }
        </div>
      </div>
    ) : (
      <a 
        className="navbar-item"
        key={index} 
        href={item.to}
      >
        {item.title}
      </a>                  
    )
  )
}

export default NavbarLeftButton