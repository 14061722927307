import  React from 'react'

type ButtonProps = {
  size?: "large" | "medium" | "normal" | "small"
}

const GetStartedButton = ({
  size
}: ButtonProps) => {

  function sizeClass(sizeName: any) {
    switch(sizeName) {
      case "large": return "is-large"
      case "small": return "is-small"
      case "normal": return "is-normal"
      case "medium": return "is-medium"
      default: return ""
    }
  }

  return(
    <button
      className={`button is-family-secondary get-started-button has-background-firefly has-text-weight-bold has-text-white is-uppercase ${sizeClass(size)}`}
    >
      <a
      href="https://app.framery.in"
      target="_blank"
      style={{"color": "white"}}
      >
        Get Started
      </a>
    </button>
  )
}

export default GetStartedButton