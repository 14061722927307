/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import {BackTop} from 'antd'
import Header from "../Header"
import {
  UpCircleOutlined
} from '@ant-design/icons'
import Footer from "../Footer"

type Props = {
  children: React.ReactNode
}

const topButtonStyle = {
  height: 40,
  width: 40,
  lineHeight: '40px',
  borderRadius: 40,
  backgroundColor: '#ee233d',
  color: '#fff',
  textAlign: "center",
  fontSize: 25,
} as React.CSSProperties;

const Layout = ({ 
  children
}: Props) => {

  return (
    <>
      <BackTop>
        <div style={topButtonStyle} >
          <UpCircleOutlined 
            title="Back to Top"
          />
        </div>
      </BackTop>
      <Header />
      <div
/*         style={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `0 1.0875rem 1.45rem`,
        }} */
      >
        <main>{children}</main>
        <Footer />
{/*         <footer
          style={{
            marginTop: `2rem`,
          }}
        >
          © {new Date().getFullYear()}, Built with
          {` `}
          <a href="https://www.gatsbyjs.com">Gatsby</a>
        </footer> */}
      </div>
    </>
  )
}

export default Layout
