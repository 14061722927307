import React from 'react'
import Wrapper from '../Wrapper'
import GetStartedButton from '../commonComponents/GetStartedButton'

const mainTitleStyles: React.CSSProperties = {
  letterSpacing: '5.85px'
}

const subHeadStyles: React.CSSProperties = {
  letterSpacing: '0.67px'
}

const lowTextStyles: React.CSSProperties = {
  letterSpacing: '0.34px'
}

const MainSlide = () => {

  return(
    <Wrapper
      colorBg
    >
      <div
        className="section has-text-centered has-text-white"
        style={{
          marginTop: '60px'
        }}
      >
        <h1 
          className="title has-text-weight-bold is-capitalized has-text-white
            is-size-1-desktop 
            pb-6 "
          style={mainTitleStyles}
        >
          Influencer Marketing Made Easy
        </h1>
        <h2 
          className="subtitle has-text-white has-text-weight-regular
            is-size-4-desktop
            pb-5"
          style={subHeadStyles}
        >
          One platform to track your Instagram account mentions
          <br /> and visualise the metrics and see your growth.
        </h2>
        <GetStartedButton 
          size="large"
        />
        <h4 
          className="pt-2 has-text-white is-uppercase is-size-7 is-text-weight-normal"
          style={lowTextStyles}
        >
          No Credit Card Needed
        </h4>
      </div>
{/*       <div
        className="block pb-2 is-pulled-right"
      >
        <img 
          style={{
            maxWidth: '300px'
          }}
          src="/img/influencerbit-top.png"
        />
      </div> */}
    </Wrapper>  
  )
}

export default MainSlide