import CreateTicket from "./CreateTicket";
import {message} from 'antd'

const success = () => {
  message.success('Your Ticket has been created. We will get back shortly.', 6);
};

const error = () => {
  message.error('Something went wrong.', 6);
};

function TicketFunction(email, name, subject, description, product) {
  CreateTicket(email, name, subject, description, product)
    .then(r => {
      if(r.id) {
        success()
      } else {
        error()
      }
    })
}

export default TicketFunction