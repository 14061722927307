import React, {useState} from 'react'
import TicketFunction from '../../common/TicketApiCalls/TicketFunction'
import './contact.sass'

const APIKEY = "NLybTAXgSHObJLCLIT"

const ContactForm = () => {
  const [isValid, setisValid] = useState(false)
  const [values, setValues]  = useState({
    email: '',
    name: '',
    subject: '',
    message: ''
  })

  const submitToken = (e) => {
    e.preventDefault()
    console.log(values.email, values.name, values.subject, values.message, 'Framery')
    TicketFunction(values.email, values.name, values.subject, values.message, 'Framery')
  }


  return(
    <form
    name="contact"
    id="contact-form"
   onSubmit={submitToken}
  >
    {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
    <input type="hidden" name="form-name" value="contact-us"  />

    <div className="field">
      <div className="control">
        <input
          className="contact-input input"
          type={'text'}
          name={'name'}
          onChange={e => setValues({...values, name: e.target.value})}
          id={'name'}
          required={true}
          placeholder="Your name"
        />
      </div>
    </div>
    <div className="field">
      <div className="control">
        <input
          className="input contact-input"
          type={'email'}
          name={'email'}
          onChange={e => setValues({...values, email: e.target.value})}
          id={'email'}
          required={true}
          placeholder="Your Email"
        />
      </div>
    </div>
    <div className="field">
      <div className="control">
        <input
          className="input contact-input"
          name={'subject'}
          onChange={e => setValues({...values, subject: e.target.value})}
          id={'subject'}
          required={true}
          placeholder="Subject"
        />
      </div>
    </div>
    <div className="field">
      <div className="control">
        <textarea
          className="textarea contact-input"
          name={'message'}
          onChange={e => setValues({...values,message: e.target.value})}
          id={'message'}
          required={true}
          placeholder="Description"
        />
      </div>
    </div>
    <div className="field">
      <button className="button is-link" type="submit">
        Send
      </button>
    </div>
  </form>
  )
}

export default ContactForm