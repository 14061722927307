import React from 'react'
import Wrapper from '../Wrapper'
import FeatureRow from './FeatureRow'

type featureItem = {
  id: number,
  title: string,
  keyword: string,
  description: string,
  link?: string,
  img?: string
}

const featureItems: featureItem[] = [
  {
    id: 1,
    title: 'Track who all are mentioning you on Instagram at a single place',
    keyword: 'Track',
    description: 'Stop the Google spreadsheet tracking madness with our Instagram Account tracking. If someone mentions you in their posts, we will get you the details all at one place. Who mentioned, where and what! As simple as that.',
    img: '/img/waiting.png'
  },
  {
    id: 2,
    title: 'Track & Measure your Instagram Account Metrics Graphically',
    keyword: 'ANALYTICS',
    description: 'Let the data make the decisions for you. Visualise and track your account\'s metrics W.R.T time like Impressions, Follower count, Text Message Clicks, Profile Views, Website Views, Online Followers, etc' ,
    img: '/img/waiting.png'
  },
  // {
  //   id: 3,
  //   title: 'Reach Out and Connect',
  //   keyword: 'REACH OUT',
  //   description: 'Massively save time by finding the targeted genre people from targeted location speaking targeted language with desired engagement rate and follower count from our constantly updating database of 10M+ influencers.',
  //   img: '/img/waiting.png'
  // },
  // {
  //   id: 4,
  //   title: 'Track these Influencers & your campaign',
  //   keyword: 'TRACKING',
  //   description: 'Massively save time by finding the targeted genre people from targeted location speaking targeted language with desired engagement rate and follower count from our constantly updating database of 10M+ influencers.',
  //   img: '/img/waiting.png'
  // }
]

const Features = () => {
  return(
    <Wrapper>
      <div>
        {featureItems.map((item: featureItem) => (
          <FeatureRow 
            title={item.title}
            key={item.id}
            img={item.img}
            desc={item.description}
            subtitle={item.keyword}
            reversed={item.id%2 === 0}
          />
        ))}
      </div>
    </Wrapper>
  )
}

export default Features