import * as React from 'react'
import MainSlide from './MainSlide'
import AppDemo from '../AppDemo'
import Steps from '../Steps'
import Stats from '../Stats'
import Features from '../Features'
import Comparision from '../Comparision'
import Highlight from '../Highlight'
import WaitingSlide from '../WaitingSlide'
import Testimonials from '../Testimonials'
import Pricing from '../Pricing'
import Contact from '../Contact'

const Main = () => {
  return(
    <>
      <MainSlide />
      {/*<AppDemo />*/}
      {/*<Steps />*/}
      {/*<Stats />*/}
      <Features />
      {/*<Testimonials />*/}
      {/*<Pricing />*/}
      {/*<Comparision />*/}
      <WaitingSlide />
      <Contact />
    </>
  )
}

export default Main